import * as React from 'react'
import Layout from '../components/Layout'
import * as Styles from '../styles/layout.module.scss'

const Impressum = ({ pageContext }) => {

    return (
        <Layout title="Impressum">
            <div className={Styles.centeredLayout}>
            <div className="entry-content clear" itemprop="text">
                <h2>
                    Angaben gemäß § 5 TMG</h2>
                <p>
                    Brandeis Consulting GmbH<br />
                    Rheindammstr. 5<br />
                    68163 Mannheim</p>
                <p>
                    Handelsregister: HRB 735382<br />
                    Registergericht: Amtsgericht Mannheim</p>
                <p>
                    <strong>
                        Vertreten durch:</strong>
                    <br />
                    Jörg Brandeis</p>
                <h2>
                    Kontakt</h2>
                <p>
                    Telefon: +49 (0) 621 48494961<br />
                    Telefax: +49 (0) 621 48494962<br />
                    E-Mail: info@brandeis.de</p>
                <h2>
                    Umsatzsteuer-ID</h2>
                <p>
                    Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:<br />
                    DE328833551</p>
                <h2>
                    Angaben zur Berufshaftpflichtversicherung</h2>
                <p>
                    <strong>
                        Name und Sitz des Versicherers:</strong>
                    <br />
                    Versicherer: 	StarStone Insurance SE<br />
                    Niederlassung für Deutschland<br />
                    Spichernstraße 8, 50672 Köln<br />
                    Telefon: +49 (0)221 95270-0<br />
                    Fax:       +49 (0)221 95270-270<br />
                    E-Mail:    cologne@starstone.com<br />
                    <br />
                    Handlungsbevollmächtigter: Jörg Raschke<br />
                    Betreuender Makler: 	iT-haftpflicht.Versicherung<br />
                    Versicherungsportal für IT-Experten und IT-Dienstleister<br />
                    KuV24 GmbH<br />
                    Karlstraße 99 in 89073 Ulm<br />
                    Telefon: +49 (731) 27703-61<br />
                    Telefax: +49 (731) 27703-961<br />
                    E-Mail: info@iT-haftpflicht.Versicherung<br />
                    Internet: www.iT-haftpflicht.Versicherung</p>
                <p>
                    <strong>
                        Geltungsraum der Versicherung:</strong>
                    <br />
                    Weltweit</p>
                <h2>
                    Redaktionell Verantwortlicher</h2>
                <p>
                    Brandeis Consulting GmbH<br />
                    Rheindammstr. 5<br />
                    68163 Mannheim<br />
                    Geschäftsführer Jörg Brandeis<br />
                    Amtsgericht Mannheim HRB 735382<br />
                    <br />
                    Kontakt<br />
                    Telefon: +49 (0) 621 48494961<br />
                    info@brandeis.de</p>
                <h2>
                    EU-Streitschlichtung</h2>
                <p>
                    Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">
                        https://ec.europa.eu/consumers/odr/</a>
                    .<br />
                    Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
                <h2>
                    Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h2>
                <p>
                    Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
                <h3>
                    Haftung für Inhalte</h3>
                <p>
                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.</p>
                <p>
                    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
                <h3>
                    Haftung für Links</h3>
                <p>
                    Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.</p>
                <p>
                    Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
                <h3>
                    Urheberrecht</h3>
                <p>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.</p>
                <p>
                    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
                <h2>
                    Kontaktformular</h2>
                <p>
                    Wenn Sie Fragen haben oder ein Angebot wünschen, dann können Sie gerne das  Kontaktformular unten nutzen oder mir eine E-Mail schicken. Auf alle anderen Anfragen werde ich mich zeitnah bei Ihnen melden.</p>

            </div>
            </div>
        </Layout>
    )
}

export default Impressum